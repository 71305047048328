import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-nd-spinner',
    templateUrl: './nd-spinner.component.html',
    styleUrls: ['./nd-spinner.component.scss']
})
export class NdSpinnerComponent {
    @Input() large!: boolean;
    @Input() medium!: boolean;
    @Input() tiny!: boolean;
}
