import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import Timeout = NodeJS.Timeout;

@Component({
    selector: 'app-nd-spinner-overlay',
    templateUrl: './nd-spinner-overlay.component.html',
    styleUrls: ['./nd-spinner-overlay.component.scss']
})
export class NdSpinnerOverlayComponent implements OnInit, OnChanges {
    showCancelButton!: boolean;
    @Input() toggle!: boolean;
    @Input() progress!: string;
    @Input() containerSelector!: string;
    @Input() fixed!: string;
    @Input() timeout!: string;
    private timeToCancel = 10000;
    private previousTimeout!: Timeout;

    constructor(
        private Renderer: Renderer2,
        private Element: ElementRef
    ) {}

    ngOnInit(): void {
        this.showCancelButton = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.disableScrolling(this.toggle);
    }

    private disableScrolling(toggle: boolean): void {
        this.showCancelButton = false;

        if (
            this.containerSelector &&
            this.Element.nativeElement.querySelector(this.containerSelector)
        ) {
            this.Renderer.setStyle(
                this.Element.nativeElement.querySelector(
                    this.containerSelector
                ),
                'overflow',
                toggle ? 'hidden' : ''
            );
        }

        if (this.previousTimeout) {
            // Cancel previous calls to show cancel button
            clearTimeout(this.previousTimeout);
        }

        if (this.timeout !== undefined && toggle) {
            this.previousTimeout = setTimeout(() => {
                this.showCancelButton = true;
            }, this.timeToCancel);
        }
    }
}
