import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-nd-spinner-inline',
    templateUrl: './nd-spinner-inline.component.html',
    styleUrls: ['./nd-spinner-inline.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NdSpinnerInlineComponent {
    @Input() toggle!: boolean;
}
