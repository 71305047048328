<div
    class="nd-spinner"
    [ngClass]="{
        large: large !== undefined,
        medium: medium !== undefined,
        tiny: tiny !== undefined
    }">
    <div class="nd-spinner__wrapper">
        <div class="nd-spinner__rotator">
            <div class="nd-spinner__inner-spin"></div>
            <div class="nd-spinner__inner-spin"></div>
        </div>
    </div>
</div>
