import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NdSpinnerInfiniteScrollComponent } from './components/nd-spinner-infinite-scroll/nd-spinner-infinite-scroll.component';
import { NdSpinnerInlineComponent } from './components/nd-spinner-inline/nd-spinner-inline.component';
import { NdSpinnerOverlayComponent } from './components/nd-spinner-overlay/nd-spinner-overlay.component';
import { NdSpinnerComponent } from './components/nd-spinner/nd-spinner.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, InfiniteScrollModule],
    declarations: [
        NdSpinnerComponent,
        NdSpinnerInfiniteScrollComponent,
        NdSpinnerInlineComponent,
        NdSpinnerOverlayComponent
    ],
    exports: [
        NdSpinnerComponent,
        NdSpinnerInfiniteScrollComponent,
        NdSpinnerInlineComponent,
        NdSpinnerOverlayComponent
    ]
})
export class NdSpinnersModule {}
