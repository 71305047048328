import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-nd-spinner-infinite-scroll',
    templateUrl: './nd-spinner-infinite-scroll.component.html',
    styleUrls: ['./nd-spinner-infinite-scroll.component.scss']
})
export class NdSpinnerInfiniteScrollComponent {
    @Input() toggle!: boolean;
}
