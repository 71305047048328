<div
    [ngClass]="{
        'nd-spinner-overlay--fixed': fixed,
        'nd-spinner-overlay': !fixed,
        hidden: !toggle
    }"
    class="nd-spinner-fade">
    <app-nd-spinner
        *ngIf="progress === undefined"
        [large]="true"></app-nd-spinner>

    <div
        *ngIf="progress !== undefined"
        class="nd-spinner-overlay__progress-text">
        {{ progress }}%
    </div>
</div>
